import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { ONBOARDING_PATH } from '../routeConstants'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(`${ONBOARDING_PATH}?utmSource=0326taxplanwebinarform`)
  }, [])
  return null
}
